.viernulvier {
  margin-left: auto !important;
  margin-right: auto !important;
}
.divi {
  min-width: 100vw;
}
.newEvent {
  align-items: right;
  text-align: right;
  border-radius: 20px !important;
  color: white;
  background-color: blue;
}

.padtop {
  padding-top: 50px !important;
}
