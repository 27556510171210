.mainDiv {
  align-items: stretch;
  text-align: left;
  justify-items: left;
  margin-top: 30%;
  padding-left: 100px;
  padding-right: 100px;
}
.rounded {
  border-radius: 20px !important;
  background-color: rgb(151, 255, 248) !important;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3) !important;
}

.centered {
  margin: auto;
}

.title {
  padding-bottom: 20px;
}

/* .maxheight {
  max-height: 50vh !important;
  max-width: 75% !important;
  margin-left: auto !important;
  margin-right: auto !important;
} */
.container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.peepee {
  padding-left: 20px;
  padding-top: 5px;
}
.divider {
  margin-top: -10px;
  border-bottom: 1px solid rgba(128, 128, 128, 0.331);
}
.newEvent {
  align-items: right;
  text-align: right;
  border-radius: 20px !important;
  background-color: #8d2ce3 !important;
  color: white !important;
  z-index: 1 !important;
}
.eventcreator {
  align-items: flex-end !important;
  text-align: right !important;
  margin-left: auto !important;
  margin-right: -25% !important;
}

.padbottom {
  padding-bottom: 10px;
}
.padtop {
  padding-top: 50px !important;
}
.dailysched {
  box-shadow: 10px 10px 5px 5px rgba(173, 216, 230, 0.385);
}
.kalend {
  min-height: 50vh;
}
