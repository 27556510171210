.mainPopup {
  margin-top: 25vh;
}
.stringInputs {
  margin-top: 10px;
  margin-bottom: 10px;
}
.modal-footer {
  justify-content: center;
}
.btn-primary {
  color: #ffffff;
  background-color: #8d2ce3;
  border-color: #8d2ce3;
  /* focus-shadow-rgb: #{to-rgb(mix($color, $border, 15%))}; */
}
.btn-primary:hover {
  color: #8d2ce3;
  background-color: #f7c6f6;
  border-color: #f7c6f6;
}
.btn-primary:focus {
  color: #ffffff;
  background-color: #8d2ce3;
  border-color: #8d2ce3;
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5);
}
.btn-active {
  color: #ffffff;
  background-color: #8d2ce3;
  border-color: #8d2ce3;
}
.btn-disabled {
  color: #ffffff;
  background-color: #8d2ce3;
  border-color: #8d2ce3;
}
.paddingontop {
  padding-top: 20px;
}
