.txtBox {
  padding-right: 90px;
  margin-bottom: -10px !important;
  padding-top: 10px;
  max-width: 100%;
  min-width: 90%;
  text-align: left;
}
.txtBox2 {
  padding-left: 50px;
  padding-right: 5px;
  padding-top: 10px;
}

.card {
  margin-left: 20px;
  margin-right: 20px;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
  max-width: 500px;
}

.kontainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}
