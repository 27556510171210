.App {
  text-align: center;
  justify-content: center;
  margin: auto;
}
/* .root {
  margin: auto;
} */

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.bg-dark {
  background-color: #282c34 !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.navb {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: aqua;
}

.centered {
  min-width: 100vw;
}

.containerTheSequel {
  min-width: 100vw;
}
.centerthisbish {
  margin-left: auto;
  margin-right: auto;
}
.navbarUserinfo {
  max-height: 40px;
  color: white;
  padding-left: 20px;
  padding-right: 20px;
}
.navbarUserinfoPicture {
  max-height: 40px;
}
.btnNavbar {
  min-width: 100px;
}
.navbarLogo {
  max-height: 30px;
  padding-top: -10px;
  padding-bottom: -10px;
  padding-left: 20px;
  padding-right: 20px;
}
.removePadding {
  padding: 0px !important;
}
/* .centerIcon {
  margin-left: 1vw;
  margin-right: -1vw;
} */
.removePaddingLeft {
  padding-left: 0px !important;
}
.mainCenter {
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: center;
}
.maxwidthTC {
  max-width: 50vw;
}
